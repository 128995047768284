var Extra = Extra || {}

Extra.Modal = {
    render: ({ Content, ClassName = null }) => {
        return `
            <div class="modal">
                <div class="modal-content-container ${ClassName}">
                    <div class="modal-content"> ${Content} </div>
                </div>
            </div>
        `
    }
}